<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="6" :xs="24"><el-input v-model="form.productId" clearable
							placeholder="请输入产品ID"></el-input></el-col>
					<el-col :span="12" :xs="24">
						<el-button type="primary" @click="jouShare">搜索</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="addedCl">新增</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="refreshCache">刷新缓存</el-button>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
					stripe>
					<el-table-column prop="productId" label="产品ID"></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<el-table-column prop="wxAppid" label="微信appId" width="200"></el-table-column>
					<el-table-column prop="wxSecret" label="微信secret" width="300"></el-table-column>
					<el-table-column prop="alipayAppid" label="支付宝appId" width="200"></el-table-column>
					<el-table-column prop="wechatPayMerchantid" label="微信支付商户号" width="120"></el-table-column>
					<el-table-column prop="wechatWithdrawMerchantid" label="微信提现商户号" width="120"></el-table-column>
					<el-table-column prop="alipayNotifyUrl" width="250" label="支付宝异步通知url"></el-table-column>
					<el-table-column prop="alipayReturnUrl" width="250" label="支付宝返回页面url"></el-table-column>
					<el-table-column prop="wechatPayNotifyUrl" width="250" label="微信异步通知url"></el-table-column>
					<el-table-column label="激励视频场景" width="300">
						<template v-slot="scope">
							<el-space :size="10" spacer="|">
								<div v-for="item in scope.row.playedScenes" :key="item">
									<template v-for="int in queryScenes" :key="int.key">
										<span v-if="item == int.key">{{ int.value }}</span>
									</template>
								</div>
							</el-space>
						</template>
					</el-table-column>

					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" align="center" fixed="right">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
						:total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
				<!-- 新增-产品 -->
				<el-dialog v-model="dialogAdd" title="新增产品" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="150px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="产品ID" prop="productId"><el-input placeholder="请输入产品ID"
										v-model="ruleForm.productId"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="名称" prop="name"><el-input placeholder="请输入名称"
										v-model="ruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信appId" prop="wxAppid"><el-input placeholder="请输入微信appId"
										v-model="ruleForm.wxAppid"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信secret" prop="wxSecret"><el-input placeholder="请输入微信secret"
										v-model="ruleForm.wxSecret"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="支付宝appId"><el-input placeholder="请输入支付宝appId"
										v-model="ruleForm.alipayAppid"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信支付商户号">
									<el-input placeholder="请输入微信支付商户号"
										v-model="ruleForm.wechatPayMerchantid"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信提现商户号">
									<el-input placeholder="请输入微信提现商户号"
										v-model="ruleForm.wechatWithdrawMerchantid"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="支付宝异步通知url"><el-input placeholder="请输入支付宝异步通知url"
										v-model="ruleForm.alipayNotifyUrl"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="支付宝返回页面url" prop="alipayReturnUrl"><el-input
										placeholder="请输入支付宝返回页面url"
										v-model="ruleForm.alipayReturnUrl"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信异步通知url" prop="wechatPayNotifyUrl"><el-input
										placeholder="请输入微信异步通知url"
										v-model="ruleForm.wechatPayNotifyUrl"></el-input></el-form-item>
							</el-col>

							<el-col :span="12" :xs="24">
								<el-form-item label="产品初始化数据">
									<el-select v-model="ruleForm.initFromProductId" clearable placeholder="请选择产品">
										<el-option v-for="item in options" :key="item.key" :label="item.value"
											:value="item.key"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="24" :xs="24">
								<el-form-item label="激励视频场景">
									<el-checkbox-group v-model="ruleForm.queryScenechecked">
										<el-checkbox v-for="item in queryScenes" :key="item.key" :label="item.key">{{
											item.value }}</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-产品 -->
				<el-dialog v-model="dialogEdit" title="修改产品" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="Editrules" ref="EditruleFormRef" label-width="150px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="产品ID" prop="productId"><el-input
										v-model="EditruleForm.productId"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="名称" prop="name"><el-input
										v-model="EditruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信appId" prop="wxAppid"><el-input
										v-model="EditruleForm.wxAppid"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信secret" prop="wxSecret"><el-input
										v-model="EditruleForm.wxSecret"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="支付宝appId"><el-input placeholder="请输入支付宝appId"
										v-model="EditruleForm.alipayAppid"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信支付商户号"><el-input placeholder="请输入微信支付商户号"
										v-model="EditruleForm.wechatPayMerchantid"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信提现商户号"><el-input placeholder="请输入微信提现商户号"
										v-model="EditruleForm.wechatWithdrawMerchantid"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="支付宝异步通知url"><el-input placeholder="请输入支付宝异步通知url"
										v-model="EditruleForm.alipayNotifyUrl"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="支付宝返回页面url"><el-input placeholder="请输入支付宝返回页面url"
										v-model="EditruleForm.alipayReturnUrl"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="微信异步通知url"><el-input placeholder="请输入微信异步通知url"
										v-model="EditruleForm.wechatPayNotifyUrl"></el-input></el-form-item>
							</el-col>
							<el-col :span="24" :xs="24">
								<el-form-item label="激励视频场景">
									<el-checkbox-group v-model="EditruleForm.queryScenechecked">
										<el-checkbox v-for="item in queryScenes" :key="item.key" :label="item.key">{{
											item.value }}</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'productInfo',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,

			options: [],
			form: {
				productId: ''
			},
			dialogAdd: false,
			ruleForm: {
				name: '',
				wxAppid: '',
				productId: '',
				wxSecret: '',
				initFromProductId: '',
				queryScenechecked: [],
				alipayAppid: '',
				wechatPayMerchantid: '',
				wechatWithdrawMerchantid: '',
				alipayNotifyUrl: '',
				alipayReturnUrl: '',
				wechatPayNotifyUrl: ''
			},
			rules: {
				wxSecret: [{ required: true, message: '请输入微信secret', trigger: 'blur' }],
				productId: [{ required: true, message: '请输入产品ID', trigger: 'blur' }],
				wxAppid: [{ required: true, message: '请输入微信appId', trigger: 'blur' }],
				name: [{ required: true, message: '请输入名称', trigger: 'blur' }],

			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				name: '',
				wxAppid: '',
				productId: '',
				wxSecret: '',
				queryScenechecked: [],
				alipayAppid: '',
				wechatPayMerchantid: '',
				wechatWithdrawMerchantid: '',
				alipayReturnUrl: '',
				alipayNotifyUrl: '',
				wechatPayNotifyUrl: ''
			},
			Editrules: {
				name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
				productId: [{ required: true, message: '请输入产品ID', trigger: 'blur' }],
				wxAppid: [{ required: true, message: '请输入对应appId', trigger: 'blur' }],
				wxSecret: [{ required: true, message: '请输入产品类型', trigger: 'blur' }],

			},
			queryScenes: []
		};
	},
	created() {
		// 获取产品信息
		this.Cluserlist();
		// 获取激励视频场景
		this.querySceneCl();
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
	},
	methods: {
		clickType() {
			// 获取产品类型
			this.$HTTP.post('index/queryProduct', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					if (response.data.code == 0) {
						this.options = response.data.data;
					} else {
						this.$message.error(response.data.msg);
					}
				} else {
					console.log(response.message);
				}
			});
		},
		querySceneCl() {
			// 获取激励视频场景
			this.$HTTP.post('gameProductInfo/queryScene', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					if (response.data.code == 0) {
						this.queryScenes = response.data.data;
					} else {
						this.$message.error(response.data.msg);
					}
				} else {
					console.log(response.message);
				}
			});
		},
		// 获取产品信息
		Cluserlist() {
			this.serloading = true;
			this.$HTTP.post(
				'gameProductInfo/query',
				{
					productId: this.form.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.ruleForm.name = '';
			this.$refs[name].resetFields();
		},
		// 搜索
		jouShare() {
			this.serloading = true;
			this.currentPage = 1;
			// 获取产品信息
			this.Cluserlist();
		},
		// 刷新缓存
		refreshCache() {
			this.serloading = true;
			this.$HTTP.post('gameProductInfo/refreshCache', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					if (response.data.code == 0) {
						this.serloading = false;
						this.$message.success(response.data.msg);
					} else {
						this.$message.error(response.data.msg);
					}
				} else {
					console.log(response.message);
				}
			});
		},
		addedCl() {
			// 获取产品类型
			this.clickType();
			// 获取激励视频场景
			this.querySceneCl();
			this.dialogAdd = true;
		},
		// 新增-产品
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'gameProductInfo/add',
						{
							productId: this.ruleForm.productId,
							name: this.ruleForm.name,
							wxAppid: this.ruleForm.wxAppid,
							wxSecret: this.ruleForm.wxSecret,
							initFromProductId: this.ruleForm.initFromProductId,
							playedScenes: this.ruleForm.queryScenechecked,
							alipayAppid: this.ruleForm.alipayAppid,
							wechatPayMerchantid: this.ruleForm.wechatPayMerchantid,
							wechatWithdrawMerchantid: this.ruleForm.wechatWithdrawMerchantid,
							alipayNotifyUrl: this.ruleForm.alipayNotifyUrl,
							alipayReturnUrl: this.ruleForm.alipayReturnUrl,
							wechatPayNotifyUrl: this.ruleForm.wechatPayNotifyUrl,

						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取产品信息
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			// 获取激励视频场景
			this.querySceneCl();
			this.EditruleForm.productId = row.productId;
			this.EditruleForm.name = row.name;
			this.EditruleForm.wxAppid = row.wxAppid;
			this.EditruleForm.id = row.id;
			this.EditruleForm.wxSecret = row.wxSecret;
			this.EditruleForm.queryScenechecked = row.playedScenes;
			this.EditruleForm.alipayAppid = row.alipayAppid;
			this.EditruleForm.wechatPayMerchantid = row.wechatPayMerchantid;
			this.EditruleForm.wechatWithdrawMerchantid = row.wechatWithdrawMerchantid;
			this.EditruleForm.alipayNotifyUrl = row.alipayNotifyUrl;
			this.EditruleForm.alipayReturnUrl = row.alipayReturnUrl;
			this.EditruleForm.wechatPayNotifyUrl = row.wechatPayNotifyUrl;


			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'gameProductInfo/update',
						{
							id: this.EditruleForm.id,
							productId: this.EditruleForm.productId,
							name: this.EditruleForm.name,
							wxAppid: this.EditruleForm.wxAppid,
							wxSecret: this.EditruleForm.wxSecret,
							playedScenes: this.EditruleForm.queryScenechecked,
							alipayAppid: this.EditruleForm.alipayAppid,
							wechatPayMerchantid: this.EditruleForm.wechatPayMerchantid,
							wechatWithdrawMerchantid: this.EditruleForm.wechatWithdrawMerchantid,
							alipayNotifyUrl: this.EditruleForm.alipayNotifyUrl,
							alipayReturnUrl: this.EditruleForm.alipayReturnUrl,
							wechatPayNotifyUrl: this.EditruleForm.wechatPayNotifyUrl,

						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取产品信息
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			this.Cluserlist();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取产品信息
			this.Cluserlist();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-space {
	flex-wrap: wrap;
}

.tablecell {
	white-space: nowrap; //强制不换行
	overflow: hidden; //溢出隐藏
	text-overflow: ellipsis //替换为省略号
}
</style>
